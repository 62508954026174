import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoHomeForm' })((theme) => ({
  root: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  container: {
    maxWidth: 735,
    padding: 5,
  },
  labelCell: {
    width: '50%',
  },
  valueCell: {
    width: '50%',
  },
  sectionTitle: {
    ...theme.typography.h2,
    marginTop: 40,
  },
  addSpaceTopBottom: {
    marginTop: 30,
    marginBottom: 30,
  },
  text: theme.typography.body1,
  sectionLabel: {
    ...theme.typography.h3,
    marginTop: 40,
  },
  tableContainer: {
    ...theme.mixins.tableContainer,
    marginTop: 20,
  },
  firstRow: {
    ...theme.mixins.tableFirstRow,
    '& th': {
      padding: 10,
      ...theme.typography.body1Bold,
      borderBottom: `1px solid ${theme.palette.other.border}`,
      background: theme.palette.primary.light,
    },
  },
  tableBody: {
    ...theme.mixins.simpleTableBody,
    '& tr td': {
      ...theme.typography.body1,
      padding: 10,
    },
    '& tbody:nth-child(odd) tr td': {
      backgroundColor: theme.palette.grey[200],
    },
    '& tr:nth-child(even) td': {
      backgroundColor: theme.palette.grey[200],
    },
    '& tr': {
      borderBottom: `1px solid ${theme.palette.grey[600]}`,
    },
  },
}));
