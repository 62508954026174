import { useRef } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { setPaymentOptionsAndAcksRefetchRequired } from '@ecp/features/sales/checkout';
import { DatePicker } from '@ecp/features/sales/shared/components';
import {
  calculateMaxPolicyStartDaysLimit,
  getHasUserStartedInEmbeddedModule,
  getPolicyStartDates,
  setShouldRecalc,
  useForm,
  usePolicyStartDateFields,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';
import {
  getProductDisplayNameFromProduct,
  getReducedProductNameFromProduct,
  isProductAuto,
} from '@ecp/features/shared/product';
import { useIsMobile } from '@ecp/themes/base';
import type { Field } from '@ecp/types';

import { useRecalculateOffer } from '../../../../util/useRecalculateOffer';

export const PolicyDatePickerGrid = ({
  product,
  classes,
}: {
  product: Product;
  classes: Record<string, string>;
}): React.ReactNode => {
  const poductName = getReducedProductNameFromProduct(product);
  const policyStartDateFields = usePolicyStartDateFields();
  const policyStartDateField = policyStartDateFields[poductName] as Field;
  const productDisplayName = getProductDisplayNameFromProduct(product);
  const maxDays = calculateMaxPolicyStartDaysLimit({
    isAutoProduct: isProductAuto(product),
  });
  const maxDate = dayjs().startOf('d').add(maxDays, 'd');
  const dispatch = useDispatch();
  const minDate = dayjs().startOf('d').add(1, 'd');
  const isMobile = useIsMobile();
  const initValues = useRef({});
  const policyStartDateAnswers = useSelector(getPolicyStartDates);
  const { retrieveOffer } = useRecalculateOffer();
  const isEmbeddedModule = useSelector(getHasUserStartedInEmbeddedModule);

  // We are recalculating the offer in express path when policy start date changes in embedded module EDSP-295
  const recalcOnChange = flagValues[FeatureFlags.EXPRESS_PATH] && isEmbeddedModule;

  const { validateForm } = useForm({
    fields: policyStartDateFields,
    initValues,
  });

  const handlePropertyStartDateComplete = async (value: AnswerValue): Promise<void> => {
    if (
      validateForm().isValid &&
      value &&
      policyStartDateAnswers[poductName] !== value.toString()
    ) {
      await policyStartDateField.props.actionOnComplete(value);
      if (recalcOnChange) {
        await retrieveOffer();
      } else {
        dispatch(setShouldRecalc(true));
      }
      dispatch(setPaymentOptionsAndAcksRefetchRequired(true));
    }
  };

  return (
    <Grid item xs={12} className={classes.dateInputField}>
      <DatePicker
        {...policyStartDateField.props}
        id={`${productDisplayName}PolicyStartDate`}
        label={`${productDisplayName} policy start date`}
        trackingName={`${productDisplayName.toLowerCase()}_policy_start_date`}
        trackingLabel={policyStartDateField.props.value}
        analyticsElement='policyCoveragePage.policyDatePicker'
        // eslint-disable-next-line react/jsx-no-bind
        actionOnComplete={handlePropertyStartDateComplete}
        minDate={minDate}
        maxDate={maxDate}
        className={isMobile ? classes.psdDatePicker : undefined}
        dialogClassName={classes.datePickerDialog}
        popperClassName={classes.datePickerPopper}
      />
    </Grid>
  );
};
