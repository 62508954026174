import { Typography } from '@mui/material';

import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import metadata from './metadata';

const ID = 'OccupancyType';

export const OccupancyTypeQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const occupancyType = useHomePropertyField('occupancyType');
  const {
    label = occupancyType.question?.title,
    trackingName = 'home_usage_selection',
    displayType,
  } = props;

  useAddFields({ occupancyType });

  useAddConditionValues({
    conditionalFields: [occupancyType],
    isRequiredOverride: () => true,
  });

  if (!occupancyType.exists) return null;

  if (displayType === 'Select')
    return (
      <Select
        {...occupancyType.props}
        options={occupancyType.props.options}
        id={ID}
        trackingName={trackingName}
      />
    );

  return (
    <RadioGroupWithOptions
      {...occupancyType.props}
      id={ID}
      label={<Typography variant='body4'>{label}</Typography>}
      cardSize='small'
      trackingName={trackingName}
      options={metadata.options}
    />
  );
};
