import type { FC } from 'react';

import { useEvent } from '@ecp/utils/react';

import { Link } from '@ecp/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';

export const LinkToHomePrefillSummaryPage: FC = (props) => {
  const navigateToHomePrefillSummary = useNavigateToPage(PagePath.HOME_PREFILL_SUMMARY, {
    replace: true,
    removeQuery: true,
  });

  const handleClick = useEvent(async () => {
    await navigateToHomePrefillSummary();
  });

  return (
    <Link
      component='button'
      onClick={handleClick}
      trackingLabel='HomePrefillAlert_Link'
      trackingName='HomePrefillAlert_Link'
    >
      Review & edit property details
    </Link>
  );
};
