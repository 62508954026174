import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { formatDate } from '@ecp/utils/date';

import { PhoneLink } from '@ecp/features/sales/shared/components';
import {
  getPrimaryInsuredStateCode,
  getRatingCategoryResponseForProduct,
} from '@ecp/features/sales/shared/store';
import type {
  CategoryCriteria,
  NameValueRatingCriteria,
  RootStore,
} from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { isProductAuto, isProductHome } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import type { BaseMetadataCollection, BaseStateMetadataCollection } from '@ecp/types';

import { ReviewHomePrefillAlert } from '../../Coverages/CoveragesForm/ReviewHomePrefillAlert';
import { useShouldShowReviewHomePrefillAlert } from '../../Coverages/CoveragesForm/ReviewHomePrefillAlert/useShouldShowReviewHomePrefillAlert';
import { useStyles } from './AutoHomeForm.styles';
import {
  AutoPolicyRatingCriteriaMetadata,
  DriverRatingCriteriaMetadata,
  IncidentRatingCriteriaMetadata,
  VehicleRatingCriteriaMetadata,
} from './AutoRatingCriteria';
import {
  HomeAdditionalRatingCriteriaMetadata,
  HomeBasicRatingCriteriaMetadata,
  HomeExteriorRatingCriteriaMetadata,
  HomeInteriorRatingCriteriaMetadata,
} from './HomeRatingCriteria';

interface Props {
  product: Product;
}

export const AutoHomeForm: React.FC<Props> = (props) => {
  const { product } = props;
  const { classes } = useStyles();
  const { ratingCriteriaPageAutoHomeFormHeader } = useShouldShowReviewHomePrefillAlert(product);

  const stateCode = useSelector(getPrimaryInsuredStateCode);

  const ratingCategory = useSelector((state: RootStore) =>
    getRatingCategoryResponseForProduct(state, product),
  );

  const getItemTitle = (itemMetadata?: { title: string }): string => {
    if (!itemMetadata) {
      return '';
    }

    return itemMetadata.title;
  };

  const getItemValue = (categoryCriteria?: CategoryCriteria): string => {
    if (!categoryCriteria) {
      return '';
    }
    if (categoryCriteria.key === 'fireplaceTypes') {
      return categoryCriteria.criteria
        .map((criteria) => `${criteria.value} - ${criteria.name}`)
        .join(', ');
    }

    return categoryCriteria.criteria
      .map((criteria) => {
        if (!criteria.name) {
          return `${criteria.value}`;
        }

        return `${criteria.name} ${criteria.value}`;
      })
      .join(', ');
  };

  const renderHeader = (): React.ReactElement => (
    <div className={classes.root}>
      <h2 className={classes.sectionTitle}>
        {isProductAuto(product)
          ? 'Premium calculation'
          : 'Premium and replacement cost calculation'}
      </h2>
      {ratingCriteriaPageAutoHomeFormHeader && (
        <ReviewHomePrefillAlert className={classes.addSpaceTopBottom} />
      )}
      <p className={classes.text}>
        {isProductAuto(product)
          ? 'We use information from a combination of data sources to calculate your premium. Please review the information below carefully and if you need to make any changes, call us at'
          : 'We use information from a combination of data sources to calculate your premium and estimate your dwelling’s replacement cost (Coverage A). Please review the information below carefully and if you need to make any changes, call us at'}
        <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />.
      </p>
      <h3 className={classes.sectionLabel}>Here’s some of the information we used:</h3>
    </div>
  );

  const getTableHead = (headerText: string): React.ReactElement => {
    return (
      <TableHead>
        <TableRow className={classes.firstRow}>
          <TableCell>{headerText}</TableCell>
          <TableCell align='left'>Info used</TableCell>
        </TableRow>
      </TableHead>
    );
  };
  const getHomeTableBody = (
    ratingCriteriaMetadata: BaseStateMetadataCollection,
    categoryCriteria: CategoryCriteria[],
  ): React.ReactElement => {
    return (
      <TableBody className={classes.tableBody}>
        {Object.keys(ratingCriteriaMetadata).map((key, index) => {
          const itemValue = getItemValue(categoryCriteria.find((criteria) => criteria.key === key));
          if (!itemValue || itemValue === '') {
            return null;
          }

          return (
            <TableRow key={index}>
              <TableCell className={classes.labelCell}>
                {ratingCriteriaMetadata[key].stateOptions?.[stateCode]?.title ||
                  ratingCriteriaMetadata[key].title}
              </TableCell>
              <TableCell className={classes.valueCell} align='left'>
                {itemValue}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };
  const renderHomeTables = (): React.ReactElement => (
    <div>
      {ratingCategory?.platformRatingCriteria.PROPERTY.map((home, index1) => (
        <div key={index1}>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table>
              {getTableHead('Basic details')}
              {getHomeTableBody(HomeBasicRatingCriteriaMetadata, home.categoryCriteria)}
            </Table>
          </Grid>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table>
              {getTableHead('Exterior details')}
              {getHomeTableBody(HomeExteriorRatingCriteriaMetadata, home.categoryCriteria)}
            </Table>
          </Grid>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table>
              {getTableHead('Interior details')}
              {getHomeTableBody(HomeInteriorRatingCriteriaMetadata, home.categoryCriteria)}
            </Table>
          </Grid>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table>
              {getTableHead('Additional details')}
              {getHomeTableBody(HomeAdditionalRatingCriteriaMetadata, home.categoryCriteria)}
            </Table>
          </Grid>
        </div>
      ))}
    </div>
  );
  const getCriteriaValue = (criterias: NameValueRatingCriteria[], keys: string): string => {
    if (criterias.length === 1) {
      if (keys !== 'DATE_OCCURRED') {
        return criterias[0].value;
      }

      return formatDate(criterias[0].value, 'MM/YYYY');
    }

    return criterias.map((criteria) => criteria.value).join(', ');
  };
  const getAutoTableBody = (
    ratingCriteriaMetadata: BaseMetadataCollection,
    criteria: CategoryCriteria[],
  ): React.ReactElement => {
    return (
      <TableBody className={classes.tableBody}>
        {criteria.map((item, index2) => {
          if (!item.criteria) {
            return null;
          }

          return (
            <TableRow key={index2}>
              <TableCell className={classes.labelCell}>
                {getItemTitle(ratingCriteriaMetadata[item.key])}
              </TableCell>
              <TableCell className={classes.valueCell} align='left'>
                {getCriteriaValue(item.criteria, item.key)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };
  const renderAutoTables = (): React.ReactElement => (
    <div>
      {ratingCategory?.platformRatingCriteria.policy.map((policy, index1) => (
        <Grid item xs={12} className={classes.tableContainer} key={index1}>
          <Table>
            {getTableHead('Policy information')}
            {getAutoTableBody(AutoPolicyRatingCriteriaMetadata[product], policy.categoryCriteria)}
          </Table>
        </Grid>
      ))}
      {ratingCategory?.platformRatingCriteria.drivers.map((driver, index1) => (
        <Grid key={index1} item xs={12} className={classes.tableContainer}>
          <Table>
            {getTableHead(`Driver ${index1 + 1} information`)}
            {getAutoTableBody(DriverRatingCriteriaMetadata[product], driver.categoryCriteria)}
          </Table>
        </Grid>
      ))}
      {ratingCategory?.platformRatingCriteria.incidents &&
        ratingCategory?.platformRatingCriteria.incidents.map((incident, index1) => {
          if (
            !incident.categoryCriteria ||
            incident.categoryCriteria.filter((item) => !!item.criteria).length === 0
          ) {
            return null;
          }

          return (
            <Grid key={index1 + 1} item xs={12} className={classes.tableContainer}>
              <Table>
                {getTableHead('Accident & violation history')}
                {getAutoTableBody(
                  IncidentRatingCriteriaMetadata[product],
                  incident.categoryCriteria,
                )}
              </Table>
            </Grid>
          );
        })}
      {ratingCategory?.platformRatingCriteria.vehicles.map((vehicle, index1) => (
        <Grid key={index1 + 1} item xs={12} className={classes.tableContainer}>
          <Table>
            {getTableHead(`Vehicle information - vehicle ${index1 + 1}`)}
            {getAutoTableBody(VehicleRatingCriteriaMetadata[product], vehicle.categoryCriteria)}
          </Table>
        </Grid>
      ))}
    </div>
  );

  return (
    <div className={classes.root}>
      {renderHeader()}
      {isProductAuto(product) && ratingCategory && renderAutoTables()}
      {isProductHome(product) && ratingCategory && renderHomeTables()}
    </div>
  );
};
