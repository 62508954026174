import { merge } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import {
  GraphicHomeCoverageAdditionalLivingExpenseImageUrl,
  GraphicHomeCoverageAdditionalMoldProtectionImageUrl,
  GraphicHomeCoverageComputersImageUrl,
  GraphicHomeCoverageDeductibleImageUrl,
  GraphicHomeCoverageDetachedStructureImageUrl,
  GraphicHomeCoverageDwellingImageUrl,
  GraphicHomeCoverageEarthquakeImageUrl,
  GraphicHomeCoverageEquipmentBreakdownImageUrl,
  GraphicHomeCoverageHomeWaterBackupImageUrl,
  GraphicHomeCoverageHurricaneLossImageUrl,
  GraphicHomeCoverageIdentityProtectionImageUrl,
  GraphicHomeCoverageIncreasedDwellingImageUrl,
  GraphicHomeCoverageLeadPoisoningImageUrl,
  GraphicHomeCoverageLossAssessmentImageUrl,
  GraphicHomeCoverageMedicalExpenseImageUrl,
  GraphicHomeCoverageMineSubsidenceCoverageImageUrl,
  GraphicHomeCoverageMineSubsidenceImageUrl,
  GraphicHomeCoverageMoldLiabilityProtectionImageUrl,
  GraphicHomeCoverageMoldProtectionCoverageImageUrl,
  GraphicHomeCoverageOrdinanceLawImageUrl,
  GraphicHomeCoveragePersonalInjuryImageUrl,
  GraphicHomeCoveragePersonalLiabilityImageUrl,
  GraphicHomeCoveragePersonalPropertyImageUrl,
  GraphicHomeCoveragePoolLiabilityImageUrl,
  GraphicHomeCoverageRoofDamageImageUrl,
  GraphicHomeCoverageServiceLinesCoverageImageUrl,
  GraphicHomeCoverageTemporaryHousingImageUrl,
  GraphicHomeCoverageWaterDeductibleImageUrl,
  GraphicHomeCoverageWindHailImageUrl,
  GraphicRentersCoverageAnimalLiabilityImageUrl,
  GraphicRentersCoverageRefrigeratedPropertyImageUrl,
  GraphicRentersCoverageSpecialPossessionsImageUrl,
  IconUISmileImageUrl,
  IconUITrophyImageUrl,
} from '@ecp/themes/base';
import type { OptionMetadata, OptionsMetadata } from '@ecp/types';

export const deductibles: { [productKey: string]: OptionsMetadata } = {
  'homesite.home': {
    'deductibles.deductible': {
      primaryImage: GraphicHomeCoverageDeductibleImageUrl,
      title: 'Standard Deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a covered loss caused by an incident other than hurricane, earthquake, or wind/hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim, up to your coverage limit. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
      stateOptions: {
        TN: {
          title: `Standard AOP deductible`,
        },
      },
    },
    'deductibles.waterDeductible': {
      primaryImage: GraphicHomeCoverageWaterDeductibleImageUrl,
      title: 'Water Deductible',
      primaryText: `If there has been a paid water loss within the past five years, the policy is subject to a deductible that applies to loss caused by any non-weather events.`,
      secondaryText: `A “paid water loss” includes losses sustained by the insured and/or their spouse/domestic partner at the insured location for which a payment was previously issued by the Company or was reported as having been issued by one of the insured’s prior insurance carriers.`,
    },
    'deductibles.windStorm': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    // NOTE: 'coverages.windstormHurricane.deductible' and 'coverages.windstormWindHail.deductible'
    // are V4 only names (though not the original v4 names, which are:
    // policyCoverages.windstormHurricane.deductible and policyCoverages.windStorm_windHail.deductible).
    'coverages.windstormHurricane.deductible': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
      stateOptions: {
        TX: {},
        NY: {},
        CT: {
          secondaryText: `This is applicable to losses due to a hurricane where a "hurricane" warning is issued or declared by the National Hurricane Center of the National Weather Service and ending 24 hours following the termination of the hurricane. Your deductible for a covered loss will be determined by multiplying the dollar amount of your Coverage A - Dwelling Limit of Liability by this percentage.`,
        },
        AL: {},
        SC: {},
      },
    },
    'coverages.windstormWindHail.deductible': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
      stateOptions: {
        // no customizations needed, but these are the states we know this field could be used for 09-2024
        TX: {},
        AL: {},
        SC: {},
      },
    },
    // NOTE: these with _XX_ are v3 only
    'deductibles.WindstormDed_TX_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_NJ_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_NY_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_TX_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_NJ_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_CT_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This is applicable to losses due to a hurricane where a "hurricane" warning is issued or declared by the National Hurricane Center of the National Weather Service and ending 24 hours following the termination of the hurricane. Your deductible for a covered loss will be determined by multiplying the dollar amount of your Coverage A - Dwelling Limit of Liability by this percentage.`,
    },
    'deductibles.hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_AL_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_AL_HurricaneLoss': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_SC_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_SC_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
  },
  'amfam-adv.home': {
    'deductibles.deductible': {
      primaryImage: GraphicHomeCoverageDeductibleImageUrl,
      title: 'Standard Deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a covered loss caused by an incident other than hurricane, earthquake, or wind/hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim, up to your coverage limit. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
      stateOptions: {
        TN: {
          title: `Standard AOP deductible`,
        },
      },
    },
    'deductibles.waterDeductible': {
      primaryImage: GraphicHomeCoverageWaterDeductibleImageUrl,
      title: 'Water Deductible',
      primaryText: `If there has been a paid water loss within the past five years, the policy is subject to a deductible that applies to loss caused by any non-weather events.`,
      secondaryText: `A “paid water loss” includes losses sustained by the insured and/or their spouse/domestic partner at the insured location for which a payment was previously issued by the Company or was reported as having been issued by one of the insured’s prior insurance carriers.`,
    },
    'deductibles.windStorm': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_TX_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_NY_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_TX_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_CT_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This is applicable to losses due to a hurricane where a "hurricane" warning is issued or declared by the National Hurricane Center of the National Weather Service and ending 24 hours following the termination of the hurricane. Your deductible for a covered loss will be determined by multiplying the dollar amount of your Coverage A - Dwelling Limit of Liability by this percentage.`,
    },
    'deductibles.hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_AL_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_AL_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_SC_WindHail': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a windstorm or hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_SC_Hurricane': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
  },
};

const baseCoverages: { [productKey: string]: OptionsMetadata } = {
  'homesite.home': {
    'coverages.dwelling': {
      primaryImage: GraphicHomeCoverageDwellingImageUrl,
      title: 'Dwelling Coverage',
      primaryText: `This amount is the minimum coverage needed to adequately insure your residence. It is an estimate based on the current cost to rebuild your home, not including the value of the land.`,
      secondaryText: `Covers expenses related to damage or loss resulting from a covered incident including, but not limited to:`,
      listItems: ['Fire or lightning', 'Windstorm or hail', 'Vandalism', 'Glass breakage', 'Smoke'],
    },
    'coverages.extendedReplacementCost': {
      primaryImage: GraphicHomeCoverageIncreasedDwellingImageUrl,
      title: 'Increased Dwelling Coverage',
      primaryText: `Increased Dwelling Coverage gives you the option of adding coverage for your Dwelling.`,
      secondaryText: `Increased Dwelling Protection is important in the event there is a covered loss to your home that exceeds your dwelling limit.`,
    },
    'coverages.otherStructures': {
      primaryImage: GraphicHomeCoverageDetachedStructureImageUrl,
      title: 'Detached Structures',
      primaryText: `Detached or Other Structures may provide coverage for additional structures on your property, not attached to the main dwelling, such as garages, pools, storage sheds, and more.`,
      secondaryText: `Damage caused to an external structure can be costly. Detached Structures coverage can reduce your out of pocket expenses if a covered incident damages your property.`,
    },
    'coverages.increasedCoverageC': {
      primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
      title: 'Personal Property',
      primaryText: `Personal Property or Possessions coverage covers your belongings - like clothing, TVs, appliances - if they're stolen or damaged in the event of a covered loss.`,
      secondaryText: `Your policy includes replacement costs for personal property. This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.`,
    },
    'coverages.includeAnimalLiabilityCoverage': {
      primaryImage: GraphicRentersCoverageAnimalLiabilityImageUrl,
      title: 'Animal Liability',
      primaryText:
        'If your dog bites or injures a neighbor, your legal fees, and their medical bills could be covered up to your liability limit.',
      secondaryText:
        'By selecting this coverage, I agree that I don’t have an exotic animal or a pet with a bite history. High-risk Dog Breeds: Akita, Alaskan Malamute, Chow Chow, Doberman, Pit Bull, Rottweiler, Staffordshire Terrier. Exotic Animals: Alligators, Crocodiles, Snakes, Primates, Large Cats, Non-domesticated.',
    },
    'coverages.includePoolLiability': {
      primaryImage: GraphicHomeCoveragePoolLiabilityImageUrl,
      title: 'Pool Liability',
      primaryText:
        'If an incident arises where a guest has an accident in, at, or around your swimming pool, your policy may cover their medical bills and any associated legal fees up to the coverage amount.',
      secondaryText: `You are not eligible if you have a pool with a diving board or slide, an in-ground pool not enclosed by a locked fence at least 4 feet high or screened enclosure, or an above-ground pool not enclosed by a locked fence at least 4 feet high or screened enclosure, unless the ladder is removable and stairs lock in an upright position when not in use.`,
    },
  },
  'amfam-adv.home': {
    'coverages.dwelling': {
      primaryImage: GraphicHomeCoverageDwellingImageUrl,
      title: 'Dwelling Coverage',
      primaryText: `This amount is the minimum coverage needed to adequately insure your residence. It is an estimate based on the current cost to rebuild your home, not including the value of the land.`,
      secondaryText: `Covers expenses related to damage or loss resulting from a covered incident including, but not limited to:`,
      listItems: ['Fire or lightning', 'Windstorm or hail', 'Vandalism', 'Glass breakage', 'Smoke'],
    },
    'coverages.extendedReplacementCost': {
      primaryImage: GraphicHomeCoverageIncreasedDwellingImageUrl,
      title: 'Increased Dwelling Coverage',
      primaryText: `Increased Dwelling Coverage gives you the option of adding coverage for your Dwelling.`,
      secondaryText: `Increased Dwelling Protection is important in the event there is a covered loss to your home that exceeds your dwelling limit.`,
    },
    'coverages.otherStructures': {
      primaryImage: GraphicHomeCoverageDetachedStructureImageUrl,
      title: 'Detached Structures',
      primaryText: `Detached or Other Structures may provide coverage for additional structures on your property, not attached to the main dwelling, such as garages, pools, storage sheds, and more.`,
      secondaryText: `Damage caused to an external structure can be costly. Detached Structures coverage can reduce your out of pocket expenses if a covered incident damages your property.`,
    },
    'coverages.increasedCoverageC': {
      primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
      title: 'Personal Property',
      primaryText: `Personal Property or Possessions coverage covers your belongings - like clothing, TVs, appliances - if they're stolen or damaged in the event of a covered loss.`,
      secondaryText: `Your policy includes replacement costs for personal property. This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.`,
    },
    'coverages.includeAnimalLiabilityCoverage': {
      primaryImage: GraphicRentersCoverageAnimalLiabilityImageUrl,
      title: 'Animal Liability',
      primaryText:
        'If your dog bites or injures a neighbor, your legal fees, and their medical bills could be covered up to your liability limit.',
      secondaryText:
        'By selecting this coverage, I agree that I don’t have an exotic animal or a pet with a bite history. High-risk Dog Breeds: Akita, Alaskan Malamute, Chow Chow, Doberman, Pit Bull, Rottweiler, Staffordshire Terrier. Exotic Animals: Alligators, Crocodiles, Snakes, Primates, Large Cats, Non-domesticated.',
    },
    'coverages.includePoolLiability': {
      primaryImage: GraphicHomeCoveragePoolLiabilityImageUrl,
      title: 'Pool Liability',
      primaryText:
        'If an incident arises where a guest has an accident in, at, or around your swimming pool, your policy may cover their medical bills and any associated legal fees up to the coverage amount.',
      secondaryText: `You are not eligible if you have a pool with a diving board or slide, an in-ground pool not enclosed by a locked fence at least 4 feet high or screened enclosure, or an above-ground pool not enclosed by a locked fence at least 4 feet high or screened enclosure, unless the ladder is removable and stairs lock in an upright position when not in use.`,
    },
  },
  'homesite.renters': {
    'coverages.personalProperty': {
      primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
      title: 'Personal Possessions',
      primaryText: `Personal Property or Possessions coverage covers your belongings - like clothing, TVs, appliances - if they're stolen or damaged in the event of a covered loss.`,
      secondaryText: `Your policy includes replacement costs for personal property. This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.`,
    },
    'coverages.personalLiability': {
      primaryImage: GraphicHomeCoveragePersonalLiabilityImageUrl,
      title: 'Personal Liability',
      primaryText: `This offers protection against property damage or bodily injury to others that you or a member of your household accidentally caused.`,
      secondaryText: `Covers your obligation to pay if you are sued for someone else’s injury or property damage. Applies whether the injury or damage takes place on your property or while you’re away from your home.`,
    },
    'coverages.moldLiabilityProtection': {
      primaryImage: GraphicHomeCoverageMoldLiabilityProtectionImageUrl,
      title: 'Mold Liability',
      primaryText: `Covers you if others file a claim against you because of mold or bacteria-related illness or damage to their property.`,
      secondaryText: `Let’s say you find out that you have mold in your home that causes one of your guests to become ill. Mold Liability Protection covers your legal fees and potential settlement/judgement if they sue – up to your coverage limit, for covered losses.`,
    },
    'coverages.medicalPaymentsToOthers': {
      primaryImage: GraphicHomeCoverageMedicalExpenseImageUrl,
      title: 'Medical Payments',
      primaryText: `Pays for medical expenses to others or to those injured on the insured property or by an animal owned by or in the care of the insured.`,
      secondaryText: `Medical Expense coverage is designed to help prevent a lawsuit if injuries are not extensive. If a lawsuit is filed, your personal liability coverage would take over.`,
    },
    'deductibles.deductible': {
      primaryImage: GraphicHomeCoverageDeductibleImageUrl,
      title: 'Standard Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a covered loss caused by an incident other than hurricane, earthquake, or wind/hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim, up to your coverage limit. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_FL_HurricaneLoss': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'coverages.windstormHurricane.deductible': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
  },
  'amfam-adv.renters': {
    'coverages.personalProperty.limit': {
      primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
      title: 'Coverage C - Personal Property',
      primaryText: 'Coverage C - Personal Property',
      secondaryText: 'Coverage C - Personal Property',
    },
    // 'coverages.lossOfUse.limit': {
    //   primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
    //   title: 'Coverage D - Loss Of Use - Limit',
    //   primaryText: 'Coverage D - Loss Of Use - Limit',
    //   secondaryText: 'Coverage D - Loss Of Use - Limit',
    // },
    // 'coverages.lossOfUse.propertyLimit': {
    //   primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
    //   title: 'Coverage D - Loss Of Use',
    //   primaryText: 'Coverage D - Loss Of Use',
    //   secondaryText: 'Coverage D - Loss Of Use',
    // },
    'coverages.personalLiability.limit': {
      primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
      title: 'Coverage E - Personal Liability',
      primaryText: 'Coverage E - Personal Liability',
      secondaryText: 'Coverage E - Personal Liability',
    },
    'coverages.personalLiability': {
      primaryImage: GraphicHomeCoveragePersonalLiabilityImageUrl,
      title: 'Personal Liability',
      primaryText: `This offers protection against property damage or bodily injury to others that you or a member of your household accidentally caused.`,
      secondaryText: `Covers your obligation to pay if you are sued for someone else’s injury or property damage. Applies whether the injury or damage takes place on your property or while you’re away from your home.`,
    },
    'coverages.moldLiabilityProtection': {
      primaryImage: GraphicHomeCoverageMoldLiabilityProtectionImageUrl,
      title: 'Mold Liability',
      primaryText: `Covers you if others file a claim against you because of mold or bacteria-related illness or damage to their property.`,
      secondaryText: `Let’s say you find out that you have mold in your home that causes one of your guests to become ill. Mold Liability Protection covers your legal fees and potential settlement/judgement if they sue – up to your coverage limit, for covered losses.`,
    },
    'coverages.medicalPaymentsToOthers': {
      primaryImage: GraphicHomeCoverageMedicalExpenseImageUrl,
      title: 'Medical Payments',
      primaryText: `Pays for medical expenses to others or to those injured on the insured property or by an animal owned by or in the care of the insured.`,
      secondaryText: `Medical Expense coverage is designed to help prevent a lawsuit if injuries are not extensive. If a lawsuit is filed, your personal liability coverage would take over.`,
    },
    'deductibles.deductible': {
      primaryImage: GraphicHomeCoverageDeductibleImageUrl,
      title: 'Standard Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a covered loss caused by an incident other than hurricane, earthquake, or wind/hail.`,
      secondaryText: `This amount will be deducted from the total payout on your claim, up to your coverage limit. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
    'deductibles.WindstormDed_FL_HurricaneLoss': {
      primaryImage: GraphicHomeCoverageHurricaneLossImageUrl,
      title: 'Hurricane Loss Deductible',
      refFieldKey: 'deductible',
      primaryText: `The amount you'd have to pay out of pocket if you experienced a loss caused by a hurricane.`,
      secondaryText: `This amount will be deducted from the total payout on your claim. You should choose a deductible at the dollar amount you'd feel comfortable paying out of pocket.`,
    },
  },
};

// Any Agent specific coverages metadata goes here
const agentCoverages = merge({}, baseCoverages, {
  'amfam-adv.renters': {
    'coverages.personalProperty': {
      primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
      title: 'Personal Possessions',
      primaryText: `Personal Property or Possessions coverage covers your belongings if they're stolen or damaged in the event of a covered loss.`,
      secondaryText: `This includes clothing, TVs, appliances, furniture, etc.`,
    },
  },
  'homesite.renters': {
    'coverages.personalProperty': {
      primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
      title: 'Personal Possessions',
      primaryText: `Personal Property or Possessions coverage covers your belongings if they're stolen or damaged in the event of a covered loss.`,
      secondaryText: `This includes clothing, TVs, appliances, furniture, etc.`,
    },
  },
});

export const coverages: { [productKey: string]: OptionsMetadata } = env.static.isAgent
  ? agentCoverages
  : baseCoverages;

export const otherCoverages: { [productKey: string]: OptionsMetadata } = {
  'homesite.home': {
    'coverages.lostOfUse': {
      primaryImage: GraphicHomeCoverageAdditionalLivingExpenseImageUrl,
      title: 'Additional Living Expenses',
      primaryText: `Covers the cost of living expenses, over and above your normal expenses, if you are unable to live in your home due to a covered loss.`,
      secondaryText: `This coverage will pay for items including (but not limited to) a hotel stay, additional food costs, and other expenses that exceed what you normally spend.`,
    },
    'coverages.personalPropertyPerilsNew': {
      primaryImage: GraphicRentersCoverageSpecialPossessionsImageUrl,
      title: 'Covered Losses - Personal Possessions',
      primaryText: `Protect your possessions with additional coverage for the things that are most important to you.`,
      secondaryText: `Choose Extended Incidents to add more protection for your stuff and cover all losses unless specifically excluded.`,
    },
    'coverages.personalLiability': {
      primaryImage: GraphicHomeCoveragePersonalLiabilityImageUrl,
      title: 'Personal Liability',
      primaryText: `This offers protection against property damage or bodily injury to others that you or a member of your household accidentally caused.`,
      secondaryText: `Covers your obligation to pay if you are sued for someone else’s injury or property damage. Applies whether the injury or damage takes place on your property or while you’re away from your home.`,
    },
    'coverages.medicalPaymentsToOthers': {
      primaryImage: GraphicHomeCoverageMedicalExpenseImageUrl,
      title: 'Medical Expense',
      primaryText: `Pays for medical expenses to others or to those injured on the insured property or by an animal owned by or in the care of the insured.`,
      secondaryText: `Medical Expense coverage is designed to help prevent a lawsuit if injuries are not extensive. If a lawsuit is filed, your personal liability coverage would take over.`,
    },
    'coverages.acvRoofCoverage': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Roof Coverage',
      primaryText: `Wind/Hail Roof losses may be adjusted based on Actual Cash Value - the depreciated value of your roof, or Replacement Cost - what it would cost to replace it.`,
      secondaryText:
        'Based on the age of your roof and the state your property is in, you may receive Actual Cash Value or Replacement Cost coverage which helps repair your roof in the event of a covered loss. In most states, Actual Cash Value is required when the age of your roof is 10 years and older.',
    },
    'coverages.roofCoverage': {
      primaryImage: GraphicHomeCoverageRoofDamageImageUrl,
      title: 'Roof Coverage',
      primaryText: `Roof Coverage will cover up to the depreciated value of your roof or for the full cost to replace it.`,
      secondaryText: `If your roof is ten or more years old, the Roof Coverage will cover up to the depreciated value of your roof. If your roof is less than ten years old, we will cover the full replacement cost, minus deductibles.`,
    },
    // v4 name is mineSubsidenceMandatory
    'coverages.mineSubsidenceMandatory.coverageValue': {
      primaryImage: GraphicHomeCoverageMineSubsidenceImageUrl,
      title: 'Mine Subsidence',
      primaryText: `Mine Subsidence provides coverage for damages due to lateral or vertical ground movement resulting from the accidental collapse of man-made, underground mines.`,
      secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions.`,
    },
    // v3 name is
    'coverages.mineSubsidence_ReadOnly': {
      primaryImage: GraphicHomeCoverageMineSubsidenceImageUrl,
      title: 'Mine Subsidence',
      primaryText: `Mine Subsidence provides coverage for damages due to lateral or vertical ground movement resulting from the accidental collapse of man-made, underground mines.`,
      secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions.`,
    },
    'coverages.timePeriodForCovD': {
      primaryImage: GraphicHomeCoverageTemporaryHousingImageUrl,
      title: 'Temporary Housing Expense',
      primaryText: `Covers the cost of living expenses, over and above your normal expenses, if you are unable to live in your home due to a covered loss.`,
      secondaryText: `This coverage will pay for items including (but not limited to) a hotel stay, additional food costs, and other expenses that exceed what you normally spend.`,
    },
    'coverages.moldPropertyProtection': {
      primaryImage: GraphicHomeCoverageMoldProtectionCoverageImageUrl,
      title: 'Mold Protection Coverage',
      primaryText: `Mold Protection provides coverage for the testing and removal of wet or dry rot, fungi, or bacteria in your home.`,
      secondaryText: `Mold Protection covers the cost to test and remove mold if it exists. It also pays to repair any damage that was caused by the mold-removal process, up to your coverage limit.`,
    },
    'coverages.moldLiabilityProtection': {
      primaryImage: GraphicHomeCoverageMoldLiabilityProtectionImageUrl,
      title: 'Mold Liability Protection',
      primaryText: `Mold Liability Protection covers you if others file a claim against you because of mold or bacteria-related illness or damage to their property.`,
      secondaryText: `Mold Liability Protection covers your legal fees and potential settlement/judgement if mold in your home causes someone to become ill and they decide to sue – up to your coverage limit, for covered losses.`,
    },
  },
  'amfam-adv.home': {
    'coverages.lostOfUse': {
      primaryImage: GraphicHomeCoverageAdditionalLivingExpenseImageUrl,
      title: 'Additional Living Expenses',
      primaryText: `Covers the cost of living expenses, over and above your normal expenses, if you are unable to live in your home due to a covered loss.`,
      secondaryText: `This coverage will pay for items including (but not limited to) a hotel stay, additional food costs, and other expenses that exceed what you normally spend.`,
    },
    'coverages.personalPropertyPerilsNew': {
      primaryImage: GraphicRentersCoverageSpecialPossessionsImageUrl,
      title: 'Covered Losses - Personal Possessions',
      primaryText: `Protect your possessions with additional coverage for the things that are most important to you.`,
      secondaryText: `Choose Extended Incidents to add more protection for your stuff and cover all losses unless specifically excluded.`,
    },
    'coverages.personalLiability': {
      primaryImage: GraphicHomeCoveragePersonalLiabilityImageUrl,
      title: 'Personal Liability',
      primaryText: `This offers protection against property damage or bodily injury to others that you or a member of your household accidentally caused.`,
      secondaryText: `Covers your obligation to pay if you are sued for someone else’s injury or property damage. Applies whether the injury or damage takes place on your property or while you’re away from your home.`,
    },
    'coverages.medicalPaymentsToOthers': {
      primaryImage: GraphicHomeCoverageMedicalExpenseImageUrl,
      title: 'Medical Expense',
      primaryText: `Pays for medical expenses to others or to those injured on the insured property or by an animal owned by or in the care of the insured.`,
      secondaryText: `Medical Expense coverage is designed to help prevent a lawsuit if injuries are not extensive. If a lawsuit is filed, your personal liability coverage would take over.`,
    },
    'coverages.acvRoofCoverage': {
      primaryImage: GraphicHomeCoverageWindHailImageUrl,
      title: 'Wind/Hail Roof Coverage',
      primaryText: `Wind/Hail Roof losses may be adjusted based on Actual Cash Value - the depreciated value of your roof, or Replacement Cost - what it would cost to replace it.`,
      secondaryText: `Based on the coverage you select, this coverage helps repair your roof in the event of a covered loss.`,
    },
    'coverages.roofCoverage': {
      primaryImage: GraphicHomeCoverageRoofDamageImageUrl,
      title: 'Roof Coverage',
      primaryText: `Roof Coverage will cover up to the depreciated value of your roof or for the full cost to replace it.`,
      secondaryText: `If your roof is ten or more years old, the Roof Coverage will cover up to the depreciated value of your roof. If your roof is less than ten years old, we will cover the full replacement cost, minus deductibles.`,
    },
    'coverages.mineSubsidence_ReadOnly': {
      primaryImage: GraphicHomeCoverageMineSubsidenceImageUrl,
      title: 'Mine Subsidence',
      primaryText: `Mine Subsidence provides coverage for damages due to lateral or vertical ground movement resulting from the accidental collapse of man-made, underground mines.`,
      secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions.`,
    },
    'coverages.timePeriodForCovD': {
      primaryImage: GraphicHomeCoverageTemporaryHousingImageUrl,
      title: 'Temporary Housing Expense',
      primaryText: `Covers the cost of living expenses, over and above your normal expenses, if you are unable to live in your home due to a covered loss.`,
      secondaryText: `This coverage will pay for items including (but not limited to) a hotel stay, additional food costs, and other expenses that exceed what you normally spend.`,
    },
    'coverages.moldPropertyProtection': {
      primaryImage: GraphicHomeCoverageMoldProtectionCoverageImageUrl,
      title: 'Mold Protection Coverage',
      primaryText: `Mold Protection provides coverage for the testing and removal of wet or dry rot, fungi, or bacteria in your home.`,
      secondaryText: `Mold Protection covers the cost to test and remove mold if it exists. It also pays to repair any damage that was caused by the mold-removal process, up to your coverage limit.`,
    },
    'coverages.moldLiabilityProtection': {
      primaryImage: GraphicHomeCoverageMoldLiabilityProtectionImageUrl,
      title: 'Mold Liability Protection',
      primaryText: `Mold Liability Protection covers you if others file a claim against you because of mold or bacteria-related illness or damage to their property.`,
      secondaryText: `Mold Liability Protection covers your legal fees and potential settlement/judgement if mold in your home causes someone to become ill and they decide to sue – up to your coverage limit, for covered losses.`,
    },
  },
  'homesite.renters': {
    'coverages.includeWaterBackup': {
      primaryImage: GraphicHomeCoverageHomeWaterBackupImageUrl,
      title: 'Water Backup / Sump Overflow',
      primaryText: `Pays to repair damage caused by a sump pump malfunction or a backup in a sewer pipe or drain that occurred off premises. Losses resulting from a flood are excluded.`,
      secondaryText: `This covers damage which is excluded from the standard renters policy.`,
    },
    'coverages.includeEquipmentBreakdown': {
      primaryImage: GraphicHomeCoverageEquipmentBreakdownImageUrl,
      title: 'Equipment Breakdown',
      primaryText: `Equipment Breakdown provides coverage for direct physical loss to covered household appliances caused by the perils of mechanical, electrical, and pressure system breakdown.`,
      secondaryText: `This coverage allows you to be reimbursed for repair or replacement of damaged appliances and/or systems due to mechanical, electrical, or pressure system breakdown.`,
    },
    'coverages.moldPropertyProtection': {
      primaryImage: GraphicHomeCoverageMoldProtectionCoverageImageUrl,
      title: 'Mold Protection',
      primaryText: `Provides coverage for the testing and removal of wet or dry rot, fungi, or bacteria in your home.`,
      secondaryText: `Let’s say you suspect your home is infested with mold. Mold Protection covers the cost to test and remove mold if it exists. It also pays to repair any damage that was caused by the mold-removal process, up to your coverage limit.`,
    },
    'coverages.includeComputers': {
      primaryImage: GraphicHomeCoverageComputersImageUrl,
      title: 'Computers',
      primaryText: `This coverage provides reimbursement for damage done to your personal computer, up to your coverage limit.`,
      secondaryText: `Choose Extended Incidents to add more protection for your computer and cover all losses unless specifically excluded.`,
    },
    'coverages.refrigeratedCoverage': {
      primaryImage: GraphicRentersCoverageRefrigeratedPropertyImageUrl,
      title: 'Refrigerated Property',
      primaryText: `This coverage pays to replace items stored in your refrigerator or freezer caused by loss of power.`,
      secondaryText: `This coverage pays to replace items stored in your refrigerator or freezer unit caused by loss of power to the refrigeration or freezer unit.`,
    },
    'coverages.includeAnimalLiabilityCoverage': {
      primaryImage: GraphicRentersCoverageAnimalLiabilityImageUrl,
      title: 'Animal Liability',
      primaryText: `If your dog bites or injures a neighbor, your legal fees, and their medical bills could be covered up to your liability limit.`,
      secondaryText: (
        <>
          By selecting this coverage, I agree that I don’t have an exotic animal or a pet with a
          bite history.
          <p>
            High-risk Dog Breeds: Akita, Alaskan Malamute, Chow Chow, Doberman, Pit Bull,
            Rottweiler, Staffordshire Terrier
          </p>
          <p>
            Exotic Animals : Alligators, Crocodiles, Snakes, Primates, Large Cats, Non-domesticated.
          </p>
        </>
      ),
      stateOptions: {
        CO: {
          secondaryText: ``,
        },
        IL: {
          secondaryText: ``,
        },
        NY: {
          secondaryText: ``,
        },
      },
    },
    'coverages.includeIdentityFraudExpenseCoverage': {
      primaryImage: GraphicHomeCoverageIdentityProtectionImageUrl,
      title: 'Identity Theft',
      primaryText: `Identity Theft Expense Protection provides coverage for costs associated with resolving issues related to a stolen identity.`,
      secondaryText: `This coverage reimburses you for legal fees and time you had to take off work – up to your coverage limit.`,
    },
    'coverages.includeLimitedAnimalLiability': {
      primaryImage: GraphicRentersCoverageAnimalLiabilityImageUrl,
      title: 'Limited Animal Liability',
      primaryText:
        'If an animal owned by an insured bites or injures someone, your legal fees, and their medical bills could be covered up to the coverage limit.',
      secondaryText:
        'This coverage is limited to $25,000. Without this endorsement, animal liability is fully excluded from your policy.',
    },
    'coverages.additionalMoldPropertyProtection': {
      primaryImage: GraphicHomeCoverageAdditionalMoldProtectionImageUrl,
      title: 'Additional Mold Protection',
      primaryText:
        'Provides increased coverage for the testing and removal of wet or dry rot, fungi, or bacteria in your home.',
      secondaryText:
        'Let’s say you suspect your home is infested with mold. Additional Mold Protection increases the covered cost to test and remove mold if it exists. It also pays to repair any damage that was caused by the mold-removal process, up to your coverage limit.',
    },
    // These keys come from QAPI through SAPI coverage questions and the requirement is to not display them
    // includeEarthquake: {
    //   primaryImage: GraphicHomeCoverageEarthquakeImageUrl,
    //   title: 'Earthquake',
    //   primaryText: `Personal Property or Possessions coverage covers your belongings - like clothing, TVs, appliances - if they're stolen or damaged in the event of a covered loss.`,
    //   secondaryText: `Your policy includes replacement costs for personal property. This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.`,
    // },
    // replacementCostOnPersonalProperty: {
    //   primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
    //   title: 'Personal Property',
    //   primaryText: `Personal Property or Possessions coverage covers your belongings - like clothing, TVs, appliances - if they're stolen or damaged in the event of a covered loss.`,
    //   secondaryText: `Your policy includes replacement costs for personal property. This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.`,
    // },
  },
  'amfam-adv.renters': {
    'coverages.includeWaterBackup': {
      primaryImage: GraphicHomeCoverageHomeWaterBackupImageUrl,
      title: 'Water Backup / Sump Overflow',
      primaryText: `Pays to repair damage caused by a sump pump malfunction or a backup in a sewer pipe or drain that occurred off premises. Losses resulting from a flood are excluded.`,
      secondaryText: `This covers damage which is excluded from the standard renters policy.`,
    },
    'coverages.includeEquipmentBreakdown': {
      primaryImage: GraphicHomeCoverageEquipmentBreakdownImageUrl,
      title: 'Equipment Breakdown',
      primaryText: `Equipment Breakdown provides coverage for direct physical loss to covered household appliances caused by the perils of mechanical, electrical, and pressure system breakdown.`,
      secondaryText: `This coverage allows you to be reimbursed for repair or replacement of damaged appliances and/or systems due to mechanical, electrical, or pressure system breakdown.`,
    },
    'coverages.moldPropertyProtection': {
      primaryImage: GraphicHomeCoverageMoldProtectionCoverageImageUrl,
      title: 'Mold Protection',
      primaryText: `Provides coverage for the testing and removal of wet or dry rot, fungi, or bacteria in your home.`,
      secondaryText: `Let’s say you suspect your home is infested with mold. Mold Protection covers the cost to test and remove mold if it exists. It also pays to repair any damage that was caused by the mold-removal process, up to your coverage limit.`,
    },
    'coverages.includeComputers': {
      primaryImage: GraphicHomeCoverageComputersImageUrl,
      title: 'Computers',
      primaryText: `This coverage provides reimbursement for damage done to your personal computer, up to your coverage limit.`,
      secondaryText: `Choose Extended Incidents to add more protection for your computer and cover all losses unless specifically excluded.`,
    },
    'coverages.refrigeratedCoverage': {
      primaryImage: GraphicRentersCoverageRefrigeratedPropertyImageUrl,
      title: 'Refrigerated Property',
      primaryText: `This coverage pays to replace items stored in your refrigerator or freezer caused by loss of power.`,
      secondaryText: `This coverage pays to replace items stored in your refrigerator or freezer unit caused by loss of power to the refrigeration or freezer unit.`,
    },
    'coverages.includeAnimalLiabilityCoverage': {
      primaryImage: GraphicRentersCoverageAnimalLiabilityImageUrl,
      title: 'Animal Liability',
      primaryText: `If your dog bites or injures a neighbor, your legal fees, and their medical bills could be covered up to your liability limit.`,
      secondaryText: (
        <>
          By selecting this coverage, I agree that I don’t have an exotic animal or a pet with a
          bite history.
          <p>
            High-risk Dog Breeds: Akita, Alaskan Malamute, Chow Chow, Doberman, Pit Bull,
            Rottweiler, Staffordshire Terrier
          </p>
          <p>
            Exotic Animals : Alligators, Crocodiles, Snakes, Primates, Large Cats, Non-domesticated.
          </p>
        </>
      ),
      stateOptions: {
        IL: {
          secondaryText: ``,
        },
        NY: {
          secondaryText: ``,
        },
      },
    },
    'coverages.includeIdentityFraudExpenseCoverage': {
      primaryImage: GraphicHomeCoverageIdentityProtectionImageUrl,
      title: 'Identity Theft',
      primaryText: `Identity Theft Expense Protection provides coverage for costs associated with resolving issues related to a stolen identity.`,
      secondaryText: `This coverage reimburses you for legal fees and time you had to take off work – up to your coverage limit.`,
    },
    'coverages.includeLimitedAnimalLiability': {
      primaryImage: GraphicRentersCoverageAnimalLiabilityImageUrl,
      title: 'Limited Animal Liability',
      primaryText:
        'If an animal owned by an insured bites or injures someone, your legal fees, and their medical bills could be covered up to the coverage limit.',
      secondaryText:
        'This coverage is limited to $25,000. Without this endorsement, animal liability is fully excluded from your policy.',
    },
    'coverages.additionalMoldPropertyProtection': {
      primaryImage: GraphicHomeCoverageAdditionalMoldProtectionImageUrl,
      title: 'Additional Mold Protection',
      primaryText:
        'Provides increased coverage for the testing and removal of wet or dry rot, fungi, or bacteria in your home.',
      secondaryText:
        'Let’s say you suspect your home is infested with mold. Additional Mold Protection increases the covered cost to test and remove mold if it exists. It also pays to repair any damage that was caused by the mold-removal process, up to your coverage limit.',
    },
    // These keys come from QAPI through SAPI coverage questions and the requirement is to not display them
    // includeEarthquake: {
    //   primaryImage: GraphicHomeCoverageEarthquakeImageUrl,
    //   title: 'Earthquake',
    //   primaryText: `Personal Property or Possessions coverage covers your belongings - like clothing, TVs, appliances - if they're stolen or damaged in the event of a covered loss.`,
    //   secondaryText: `Your policy includes replacement costs for personal property. This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.`,
    // },
    // replacementCostOnPersonalProperty: {
    //   primaryImage: GraphicHomeCoveragePersonalPropertyImageUrl,
    //   title: 'Personal Property',
    //   primaryText: `Personal Property or Possessions coverage covers your belongings - like clothing, TVs, appliances - if they're stolen or damaged in the event of a covered loss.`,
    //   secondaryText: `Your policy includes replacement costs for personal property. This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.`,
    // },
  },
};

const enableIquoteHo3Coverages = flagValues[FeatureFlags.IQUOTE_HO3_COVERAGES];
const enableIquoteHo4Coverages = flagValues[FeatureFlags.IQUOTE_HO4_COVERAGES];

export const iQuoteHo3OptionalCoverages: {
  [key: string]: OptionMetadata;
} | null = enableIquoteHo3Coverages
  ? {
      'coverages.ordinanceOrLaw': {
        primaryImage: GraphicHomeCoverageOrdinanceLawImageUrl,
        title: 'Ordinance or Law',
        primaryText:
          'Raises your base coverage limit included with your policy when new repairs and rebuilds require an additional cost to be brought up to present-day, local building codes',
      },
      'coverages.lossAssessmentCoverage': {
        primaryImage: GraphicHomeCoverageLossAssessmentImageUrl,
        title: 'Loss Assessment',
        primaryText:
          'Coverage for damage assessed by your homeowners association in the event of a loss to communal property or a claim of bodily injury or property damage arising out of the property.',
        secondaryText:
          'Provides additional coverage if you live in a shared community where you’re responsible for a portion of damage or loss in a common area.',
      },
      'coverages.otherStructures': {
        primaryImage: GraphicHomeCoverageDetachedStructureImageUrl,
        title: 'Detached Structures - Increased Limits ',
        primaryText:
          'Increases the coverage limit for permanent structures on your property that are not physically connected to your home, such as a detached garage, shed, or fence.',
        secondaryText:
          'This additional protection may benefit you if your property has a large number of detached structures or the cost to repair or replace your structures exceeds the limit on your homeowners policy.',
      },
      'coverages.refrigeratedCoverage': {
        primaryImage: GraphicRentersCoverageRefrigeratedPropertyImageUrl,
        title: 'Refrigerated Property',
        primaryText: `This coverage pays to replace items stored in your refrigerator or freezer caused by loss of power.`,
        secondaryText: `This coverage pays to replace items stored in your refrigerator or freezer unit caused by loss of power to the refrigeration or freezer unit.`,
      },
    }
  : null;

export const iQuoteHo4OptionalCoverages: {
  [key: string]: OptionMetadata;
} | null = enableIquoteHo4Coverages
  ? {
      'coverages.includeEarthquake': {
        primaryImage: GraphicHomeCoverageEarthquakeImageUrl,
        title: 'Earthquake',
        primaryText:
          'Earthquake protection covers your property for damage caused by an earthquake.',
        secondaryText:
          'This covers earthquake-related direct physical loss including land shock waves or tremors before, during, or after volcanic eruptions.',
      },
      'coverages.libel': {
        primaryImage: GraphicHomeCoveragePersonalInjuryImageUrl,
        title: 'Personal Injury',
        primaryText:
          'Personal Injury coverage pays for loss settlement and legal fees if someone sues you for acts or statements that defame their character.',
        secondaryText:
          'Examples include: false arrest, detention, imprisonment, malicious prosecution, libel, slander, defamation of character, invasion of privacy, wrongful eviction, wrongful entry.',
      },
    }
  : null;

export const optionalCoverages: { [productKey: string]: OptionsMetadata } = {
  'homesite.home': {
    'coverages.includeWaterBackup': {
      primaryImage: GraphicHomeCoverageHomeWaterBackupImageUrl,
      title: 'Water Back Up Coverage',
      primaryText: `Pays to repair damage caused by a sump pump malfunction or a backup in a sewer pipe or drain that occurred off premises. Losses resulting from a flood are excluded.`,
      secondaryText: `This covers damage which is excluded from the standard homeowners policy.`,
      stateOptions: {
        MD: {
          secondaryText: `With the “policy limits” option, you get the benefit of the full limit of liability applicable to the damaged property in the event of a covered loss.`,
        },
        VA: {
          secondaryText: `With the "policy limits" option, you get the benefit of the full limit of liability applicable to the damaged property in the event of a covered loss.`,
        },
      },
      isWaterBackup: true,
      trophyIcon: IconUITrophyImageUrl,
      smileyFaceIcon: IconUISmileImageUrl,
    },
    'coverages.includeOrdinanceOfLaw': {
      primaryImage: GraphicHomeCoverageOrdinanceLawImageUrl,
      title: 'Ordinance or Law Coverage',
      primaryText: `Ordinance or Law Coverage may help pay for the extra repair cost when a home requires additional upgrades after a covered loss to meet current building codes.`,
      secondaryText: (
        <>
          Building codes change over time, sometimes requiring repairs to use new or different
          materials, meet new safety standards, etc. These new requirements may add additional
          expense and are not covered by your standard homeowners policy.
          <p>
            When upgrades are required after a covered loss, this coverage can help with the extra
            cost. Typically, the older your home, the more likely this coverage may be helpful.
          </p>
        </>
      ),
    },
    'coverages.includeEarthquake': {
      primaryImage: GraphicHomeCoverageEarthquakeImageUrl,
      title: 'Earthquake',
      primaryText: `Earthquake protection covers your property for damage caused by an earthquake.`,
      secondaryText: `This covers earthquake-related direct physical loss including land shock waves or tremors before, during, or after volcanic eruptions.`,
      stateOptions: {
        OK: {
          primaryText: `The deductible for earthquake coverage is 5%. The deductible is applied separately to dwelling, other structures and personal property coverage. Coverage will not be covered for instances related to fracking (the process that injects water, sand or chemicals under high pressure into wells).`,
          secondaryText: `Coverage would be excluded for any of the following: extracting of oil/gas from below the earth’s surface by any process of injecting, inserting or storing any substance below the earth’s surface.`,
        },
      },
    },
    // these two are V4 only
    // 'coverages.mineSubsidence.coverageValue'
    // 'coverages.mineSubALECoverage.coverageValue'
    'coverages.mineSubsidence.coverageValue': {
      primaryImage: GraphicHomeCoverageMineSubsidenceImageUrl,
      title: 'Mine Subsidence',
      primaryText: `Mine Subsidence provides coverage for damages due to lateral or vertical ground movement resulting from the accidental collapse of man-made, underground mines.`,
      secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions.`,
      stateOptions: {
        IN: {
          secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions. If you have specific other structures which you would like to cover for the peril of mine subsidence, please contact our customer service center.`,
        },
      },
    },
    'coverages.mineSubALECoverage.coverageValue': {
      primaryImage: GraphicHomeCoverageMineSubsidenceCoverageImageUrl,
      title: 'Mine Subsidence - Additional Living Expense',
      primaryText: `If your home is unliveable due to damage caused by the collapse of a nearby mine, your additional living expenses will not be covered unless this coverage is selected.`,
    },
    // these three are v3 and deprecated
    'coverages.mineSubsidence': {
      primaryImage: GraphicHomeCoverageMineSubsidenceImageUrl,
      title: 'Mine Subsidence',
      primaryText: `Mine Subsidence provides coverage for damages due to lateral or vertical ground movement resulting from the accidental collapse of man-made, underground mines.`,
      secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions.`,
    },
    'coverages.mineSubsidence_IN': {
      primaryImage: GraphicHomeCoverageMineSubsidenceImageUrl,
      title: 'Mine Subsidence',
      primaryText: `Mine Subsidence provides coverage for damages due to lateral or vertical ground movement resulting from the accidental collapse of man-made, underground mines.`,
      secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions. If you have specific other structures which you would like to cover for the peril of mine subsidence, please contact our customer service center.`,
    },
    'coverages.mineSubALECoverage': {
      primaryImage: GraphicHomeCoverageMineSubsidenceCoverageImageUrl,
      title: 'Mine Subsidence - Additional Living Expense',
      primaryText: `If your home is unliveable due to damage caused by the collapse of a nearby mine, your additional living expenses will not be covered unless this coverage is selected.`,
    },
    'coverages.libel': {
      primaryImage: GraphicHomeCoveragePersonalInjuryImageUrl,
      title: 'Personal Injury',
      primaryText: `Personal Injury coverage pays for loss settlement and legal fees if someone sues you for acts or statements that defame their character.`,
      secondaryText: `Examples include: false arrest, detention, imprisonment, malicious prosecution, libel, slander, defamation of character, invasion of privacy, wrongful eviction, wrongful entry.`,
    },
    'coverages.includeComputers': {
      primaryImage: GraphicHomeCoverageComputersImageUrl,
      title: 'Covered Losses - Personal Computer',
      primaryText: `This coverage provides reimbursement for damage done to your personal computer, up to your coverage limit.`,
      secondaryText: `Choose Extended Incidents to add more protection for your computer and cover all losses unless specifically excluded.`,
    },
    'coverages.includeIdentityFraudExpenseCoverage': {
      primaryImage: GraphicHomeCoverageIdentityProtectionImageUrl,
      title: 'Identity Theft Expense Protection',
      primaryText: `Identity Theft Expense Protection provides coverage for costs associated with resolving issues related to a stolen identity.`,
      secondaryText: `This coverage reimburses you for legal fees and time you had to take off work – up to your coverage limit.`,
    },
    'coverages.includeEquipmentBreakdown': {
      primaryImage: GraphicHomeCoverageEquipmentBreakdownImageUrl,
      title: 'Equipment Breakdown',
      primaryText: `Equipment Breakdown provides coverage for direct physical loss to covered household appliances caused by the perils of mechanical, electrical, and pressure system breakdown.`,
      secondaryText: `This coverage allows you to be reimbursed for repair or replacement of damaged appliances and/or systems due to mechanical, electrical, or pressure system breakdown.`,
    },
    'coverages.includeServiceLine': {
      primaryImage: GraphicHomeCoverageServiceLinesCoverageImageUrl,
      title: 'Service Lines Coverage',
      primaryText: `Service Lines provides coverage for repair or replacement of underground piping or wiring that provides electrical power, heating, natural gas, waste disposal, or water to their homes and that are damaged by a covered loss.`,
      secondaryText: `If, for example, a storm uproots a tree on your property damaging the underground power line in the process. Selecting this coverage would allow you to be reimbursed for repair or replacement of the damaged power line.`,
    },
    'coverages.leadPoisoningCov': {
      primaryImage: GraphicHomeCoverageLeadPoisoningImageUrl,
      title: 'Lead Poisoning',
      primaryText: `If you rent out part of your multi-family home and it was built before 1978, lead liability will likely be excluded. By selecting this endorsement you can buy back limited coverage for your liability to tenants.`,
      secondaryText: `For example, if your renters get sick due to the presence of lead in your rented property, you may be liable for their injuries. This endorsement will protect you from bodily injury the lead may cause. To add this coverage, please contact our customer service center.`,
    },
    ...iQuoteHo3OptionalCoverages,
  },
  'homesite.renters': {
    ...iQuoteHo4OptionalCoverages,
  },
  'amfam-adv.home': {
    'coverages.includeWaterBackup': {
      primaryImage: GraphicHomeCoverageHomeWaterBackupImageUrl,
      title: 'Water Back Up Coverage',
      primaryText: `Pays to repair damage caused by a sump pump malfunction or a backup in a sewer pipe or drain that occurred off premises. Losses resulting from a flood are excluded.`,
      secondaryText: `This covers damage which is excluded from the standard homeowners policy.`,
      stateOptions: {
        MD: {
          secondaryText: `With the “policy limits” option, you get the benefit of the full limit of liability applicable to the damaged property in the event of a covered loss.`,
        },
        VA: {
          secondaryText: `With the "policy limits" option, you get the benefit of the full limit of liability applicable to the damaged property in the event of a covered loss.`,
        },
      },
      isWaterBackup: true,
      trophyIcon: IconUITrophyImageUrl,
      smileyFaceIcon: IconUISmileImageUrl,
    },
    'coverages.includeEarthquake': {
      primaryImage: GraphicHomeCoverageEarthquakeImageUrl,
      title: 'Earthquake',
      primaryText: `Earthquake protection covers your property for damage caused by an earthquake.`,
      secondaryText: `This covers earthquake-related direct physical loss including land shock waves or tremors before, during, or after volcanic eruptions.`,
      stateOptions: {
        OK: {
          primaryText: `The deductible for earthquake coverage is 5%. The deductible is applied separately to dwelling, other structures and personal property coverage. Coverage will not be covered for instances related to fracking (the process that injects water, sand or chemicals under high pressure into wells).`,
          secondaryText: `Coverage would be excluded for any of the following: extracting of oil/gas from below the earth’s surface by any process of injecting, inserting or storing any substance below the earth’s surface.`,
        },
      },
    },
    'coverages.mineSubsidence': {
      primaryImage: GraphicHomeCoverageMineSubsidenceImageUrl,
      title: 'Mine Subsidence',
      primaryText: `Mine Subsidence provides coverage for damages due to lateral or vertical ground movement resulting from the accidental collapse of man-made, underground mines.`,
      secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions.`,
    },
    'coverages.mineSubsidence_IN': {
      primaryImage: GraphicHomeCoverageMineSubsidenceImageUrl,
      title: 'Mine Subsidence',
      primaryText: `Mine Subsidence provides coverage for damages due to lateral or vertical ground movement resulting from the accidental collapse of man-made, underground mines.`,
      secondaryText: `This does not cover damages due to ground movement from earthquakes, landslides, or volcanic eruptions. If you have specific other structures which you would like to cover for the peril of mine subsidence, please contact our customer service center.`,
    },
    'coverages.mineSubALECoverage': {
      primaryImage: GraphicHomeCoverageMineSubsidenceCoverageImageUrl,
      title: 'Mine Subsidence - Additional Living Expense',
      primaryText: `If your home is unliveable due to damage caused by the collapse of a nearby mine, your additional living expenses will not be covered unless this coverage is selected.`,
      secondaryText: `For example, your property was hit with an artificially generated power surge. The water heater, security system, and refrigerator were damaged. Selecting this coverage would allow you to be reimbursed for repair or replacement of these damaged appliances and/or systems.`,
    },
    'coverages.libel': {
      primaryImage: GraphicHomeCoveragePersonalInjuryImageUrl,
      title: 'Personal Injury',
      primaryText: `Personal Injury coverage pays for loss settlement and legal fees if someone sues you for acts or statements that defame their character.`,
      secondaryText: `Examples include: false arrest, detention, imprisonment, malicious prosecution, libel, slander, defamation of character, invasion of privacy, wrongful eviction, wrongful entry.`,
    },
    'coverages.includeComputers': {
      primaryImage: GraphicHomeCoverageComputersImageUrl,
      title: 'Covered Losses - Personal Computer',
      primaryText: `This coverage provides reimbursement for damage done to your personal computer, up to your coverage limit.`,
      secondaryText: `Choose Extended Incidents to add more protection for your computer and cover all losses unless specifically excluded.`,
    },
    'coverages.includeIdentityFraudExpenseCoverage': {
      primaryImage: GraphicHomeCoverageIdentityProtectionImageUrl,
      title: 'Identity Theft Expense Protection',
      primaryText: `Identity Theft Expense Protection provides coverage for costs associated with resolving issues related to a stolen identity.`,
      secondaryText: `This coverage reimburses you for legal fees and time you had to take off work – up to your coverage limit.`,
    },
    'coverages.includeEquipmentBreakdown': {
      primaryImage: GraphicHomeCoverageEquipmentBreakdownImageUrl,
      title: 'Equipment Breakdown',
      primaryText: `Equipment Breakdown provides coverage for direct physical loss to covered household appliances caused by the perils of mechanical, electrical, and pressure system breakdown.`,
      secondaryText: `This coverage allows you to be reimbursed for repair or replacement of damaged appliances and/or systems due to mechanical, electrical, or pressure system breakdown.`,
    },
    'coverages.includeServiceLine': {
      primaryImage: GraphicHomeCoverageServiceLinesCoverageImageUrl,
      title: 'Service Lines Coverage',
      primaryText: `Service Lines provides coverage for repair or replacement of underground piping or wiring that provides electrical power, heating, natural gas, waste disposal, or water to their homes and that are damaged by a covered loss.`,
      secondaryText: `If, for example, a storm uproots a tree on your property damaging the underground power line in the process. Selecting this coverage would allow you to be reimbursed for repair or replacement of the damaged power line.`,
    },
    'coverages.leadPoisoningCov': {
      primaryImage: GraphicHomeCoverageLeadPoisoningImageUrl,
      title: 'Lead Poisoning',
      primaryText: `If you rent out part of your multi-family home and it was built before 1978, lead liability will likely be excluded. By selecting this endorsement you can buy back limited coverage for your liability to tenants.`,
      secondaryText: `For example, if your renters get sick due to the presence of lead in your rented property, you may be liable for their injuries. This endorsement will protect you from bodily injury the lead may cause. To add this coverage, please contact our customer service center.`,
    },
  },
};

export const includedCoverages: { [productKey: string]: OptionsMetadata } = {
  'homesite.renters': {
    'coverages.lostOfUse': {
      primaryImage: GraphicHomeCoverageTemporaryHousingImageUrl,
      title: 'Temporary Housing Expense',
      primaryText: `Covers the cost of living expenses, over and above your normal expenses, if you are unable to live in your home due to a covered loss.`,
      secondaryText: `This coverage will pay for items including (but not limited to) a hotel stay, additional food costs, and other expenses that exceed what you normally spend.`,
    },
  },
  'amfam-adv.renters': {
    'coverages.lostOfUse': {
      primaryImage: GraphicHomeCoverageTemporaryHousingImageUrl,
      title: 'Temporary Housing Expense',
      primaryText: `Covers the cost of living expenses, over and above your normal expenses, if you are unable to live in your home due to a covered loss.`,
      secondaryText: `This coverage will pay for items including (but not limited to) a hotel stay, additional food costs, and other expenses that exceed what you normally spend.`,
    },
  },
};
