import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { Button, Select } from '@ecp/features/sales/shared/components';
import { useForm } from '@ecp/features/sales/shared/store';
import { updateAddedRef, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';

import { HEATING_SOURCE_REF } from '../../../../constants';
import { heatingSourceMetaData } from '../../../../metadata';
import { useStyles } from './HeatingSourceQuestion.styles';

interface Props {
  open: boolean;
  onClose(): void;
  editingHeatingSourceRef: string;
}

export const HeatingSourceDialog: React.FC<Props> = (props) => {
  const { onClose, editingHeatingSourceRef } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const HEATING_SOURCE_FREE_STANDING_STOVE = 'PROPERTY.HEATING_SOURCE.FREE_STANDING_STOVE';
  const HEATING_SOURCE_FIREPLACE_INSERT = 'PROPERTY.HEATING_SOURCE.FIREPLACE_INSERT';
  const HEATING_SOURCE_BUILT_IN_FIREPLACE = 'PROPERTY.HEATING_SOURCE.BUILT_IN_FIREPLACE';

  const useHeatingSourceField = useFieldWithPrefix(editingHeatingSourceRef, 'heatingSource.<id>');
  const hsType = useHeatingSourceField('type');
  const hsFuelType = useHeatingSourceField('fuelType');
  const hsMaterial = useHeatingSourceField('material');

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    fields: {
      heatingSource: {
        type: hsType,
        fuelType: hsFuelType,
        material: hsMaterial,
      },
    },
  });

  const handleSaveHeatingSource = useCallback(async (): Promise<void> => {
    const { isValid } = validateForm();
    if (isValid) {
      await patchFormValues();
    }
    await dispatch(updateAddedRef({ type: HEATING_SOURCE_REF, newRef: editingHeatingSourceRef }));
    onClose();
  }, [dispatch, editingHeatingSourceRef, onClose, patchFormValues, validateForm]);

  const handleHeatingSourceClose = useCallback((): void => {
    if (hsFuelType.value) hsFuelType.validateAndUpdate(null);
    if (hsMaterial.value) hsMaterial.validateAndUpdate(null);
    if (hsType.value) hsType.validateAndUpdate(null);
    onClose();
  }, [onClose, hsFuelType, hsMaterial, hsType]);

  const validateHeatingSourceFields = useCallback((): boolean => {
    if (
      (hsType.value === HEATING_SOURCE_BUILT_IN_FIREPLACE && hsMaterial.value) ||
      (hsType.value && hsType.value !== HEATING_SOURCE_BUILT_IN_FIREPLACE && hsFuelType.value)
    ) {
      return false;
    }

    return true;
  }, [hsFuelType, hsMaterial, hsType]);

  const handleHeatingSourceTypeOnChange = useCallback(
    (value: AnswerValue) => {
      if (hsFuelType.value) hsFuelType.validateAndUpdate(null);
      if (hsMaterial.value) hsMaterial.validateAndUpdate(null);
      hsType.validateAndUpdate(value);
    },
    [hsFuelType, hsMaterial, hsType],
  );

  const handleHeatingSourceMaterialChange = useCallback(
    (value: AnswerValue): void => {
      if (hsFuelType.value) hsFuelType.validateAndUpdate(null);
      hsMaterial.validateAndUpdate(value);
    },
    [hsFuelType, hsMaterial],
  );

  const handleHeatingSourceFuelTypeChange = useCallback(
    (value: AnswerValue): void => {
      if (hsMaterial.value) hsMaterial.validateAndUpdate(null);
      hsFuelType.validateAndUpdate(value);
    },
    [hsMaterial, hsFuelType],
  );

  return (
    <div className={classes.newHeatSource}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <h4 className={classes.label}>Tell us about your heat sources</h4>
        </Grid>
        <GridItem xs={6} className={classes.dropdown}>
          <Select
            {...hsType.props}
            actionOnComplete={handleHeatingSourceTypeOnChange}
            options={heatingSourceMetaData.heatingSource}
            id='heatingSourceType'
            label='Heat source'
            data-testid='heatingSourceType'
            trackingName='heat_source_selection'
            disabled={isPatchFormInProgress}
          />
        </GridItem>
        {hsType.value === HEATING_SOURCE_BUILT_IN_FIREPLACE && (
          <Grid item xs={6} className={classes.dropdown}>
            <Select
              {...hsMaterial.props}
              options={heatingSourceMetaData.fireplaceType}
              actionOnComplete={handleHeatingSourceMaterialChange}
              trackingName='fuel_type_selection'
              label='Fireplace type'
              id='fireplaceType'
              data-testid='fireplaceType'
              disabled={isPatchFormInProgress}
            />
          </Grid>
        )}
        {hsType.value === HEATING_SOURCE_FREE_STANDING_STOVE && (
          <Grid item xs={6} className={classes.dropdown}>
            <Select
              {...hsFuelType.props}
              options={heatingSourceMetaData.fuelType}
              actionOnComplete={handleHeatingSourceFuelTypeChange}
              trackingName='fuel_type_selection'
              label='Fuel type'
              id='fuelType'
              data-testid='fuelType'
              disabled={isPatchFormInProgress}
            />
          </Grid>
        )}
        {hsType.value === HEATING_SOURCE_FIREPLACE_INSERT && (
          <Grid item xs={6} className={classes.dropdown}>
            <Select
              {...hsFuelType.props}
              options={heatingSourceMetaData.fuelType}
              actionOnComplete={handleHeatingSourceFuelTypeChange}
              trackingName='fuel_type_selection'
              label='Fuel type'
              id='fuelTypeFireplaceInsert'
              data-testid='fuelTypeFireplaceInsert'
              disabled={isPatchFormInProgress}
            />
          </Grid>
        )}
        <Grid item xs={8} className={classes.buttonGrid}>
          <Button
            className={classes.saveButton}
            variant='primary'
            trackingName='add_heat_source_button'
            trackingLabel='add_heat_source'
            onClick={handleSaveHeatingSource}
            disabled={validateHeatingSourceFields()}
          >
            Save heat source
          </Button>
          <Button
            className={classes.cancelButton}
            variant='iconTextMedium'
            trackingName='cancel_heat_source_button'
            trackingLabel='cancel_heat_source'
            onClick={handleHeatingSourceClose}
          >
            Cancel heat source
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
