import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { NUMBER_OF_SEPARATE_LIVING_UNITS } from '../../../../constants/questions';

export const SingleOrMultiFamilyQuestion: React.FC<QuestionProps> = (props) => {
  const singleOrMultiFamily = useField(NUMBER_OF_SEPARATE_LIVING_UNITS);
  const { trackingName = 'living_units_silder', groupLabel = singleOrMultiFamily.question?.title } =
    props;

  useInitValues({ [singleOrMultiFamily.key]: 'SINGLE_OR_MULTI_FAMILY.SINGLE_FAMILY' });
  useAddFields({ singleOrMultiFamily });

  if (!singleOrMultiFamily.exists) return null;

  return (
    <Slider
      {...singleOrMultiFamily.props}
      id='SingleOrMultiFamily'
      groupLabel={groupLabel}
      trackingName={trackingName}
      dataTestId='SingleOrMultiFamily'
    />
  );
};
